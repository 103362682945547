import React from "react";

import FormDescription from "../../../../../../../../components/RefactoredForms/FormDescription";
import FormGroup from "../../../../../../../../components/RefactoredForms/FormGroup";
import FormInput from "../../../../../../../../components/RefactoredForms/FormInput";
import FormCheckbox from "../../../../../../../../components/RefactoredForms/FormCheckbox";
import {useFormikContext} from "formik";
import FormTextarea from "../../../../../../../../components/RefactoredForms/FormTextarea";
import FormSelect from "../../../../../../../../components/RefactoredForms/FormSelect";
import companyEmploymentFormConfig from "./companyEmploymentFormConfig";
import {DatePicker} from "../../../../../../../../components/ui";


const EmploymentForm = () => {
    const {values, setFieldValue, setFieldTouched} = useFormikContext();
    return (<>
            <FormDescription title='Informazioni generali'/>
            <div className='grid xl:grid-cols-3 lg:grid-cols-2 gap-4'>
                <FormGroup required label='Stato' name='status'>
                    <FormSelect
                        name="status"
                        options={companyEmploymentFormConfig.statuses}
                        clearable={false}
                    />
                </FormGroup>
                <FormGroup label='IBAN' name='iban'>
                    <FormInput name="iban"/>
                </FormGroup>
            </div>
            <FormDescription title='Indirizzo di residenza'/>
            <div className='grid xl:grid-cols-3 lg:grid-cols-2 gap-4'>
                <FormGroup label='Indirizzo' name='residence_address.address'>
                    <FormInput name='residence_address.address'/>
                </FormGroup>
                <FormGroup label='Numero civico' name='residence_address.street_number'>
                    <FormInput name='residence_address.street_number'/>
                </FormGroup>
                <FormGroup label='Città' name='residence_address.city'>
                    <FormInput name='residence_address.city'/>
                </FormGroup>
                <FormGroup label='Cap' name='residence_address.postal_code'>
                    <FormInput name='residence_address.postal_code'/>
                </FormGroup>
                <FormGroup label='Nazione' name='residence_address.country'>
                    <FormInput name='residence_address.country'/>
                </FormGroup>
            </div>

            <FormDescription title='Indirizzo di domicilio'/>
            <FormGroup required label='' name='domicile_address_like_residence'>
                <FormCheckbox label='Usa lo stesso indirizzo di residenza'
                              name='domicile_address_like_residence'/>
            </FormGroup>
            {!values.domicile_address_like_residence &&
                <div className='grid xl:grid-cols-3 lg:grid-cols-2 gap-4'>
                    <FormGroup required label='Indirizzo' name='domicile_address.address'>
                        <FormInput name='domicile_address.address'/>
                    </FormGroup>
                    <FormGroup required label='Numero civico' name='domicile_address.street_number'>
                        <FormInput name='domicile_address.street_number'/>
                    </FormGroup>
                    <FormGroup required label='Città' name='domicile_address.city'>
                        <FormInput name='domicile_address.city'/>
                    </FormGroup>
                    <FormGroup required label='Cap' name='domicile_address.postal_code'>
                        <FormInput name='domicile_address.postal_code'/>
                    </FormGroup>
                    <FormGroup required label='Nazione' name='domicile_address.country'>
                        <FormInput name='domicile_address.country'/>
                    </FormGroup>
                </div>}

            <FormDescription title='Contatto'/>

            <div className='grid xl:grid-cols-3 lg:grid-cols-2 gap-4'>

                <FormGroup required label='Tipo contratto' name='contract_type'>
                    <FormSelect
                        name="contract_type"
                        options={companyEmploymentFormConfig.contractTypes}
                        clearable={false}
                        placeholder="Seleziona un tipo di contratto"

                    />
                </FormGroup>

                {values.contract_type?.value === 'other' &&
                    <FormGroup required label='Descrizione Altro' name='contract_type_other'>
                        <FormInput name='contract_type_other'/>
                    </FormGroup>
                }

                <FormGroup required label='Tipo orario' name='working_time_type'
                           className="col-start-1">
                    <FormSelect
                        name="working_time_type"
                        options={companyEmploymentFormConfig.workingTimeTypes}
                        clearable={false}
                        placeholder="Seleziona un tipo di orario"

                    />
                </FormGroup>

                {values.working_time_type?.value === 'part_time' &&
                    <>
                        <FormGroup required label='Percentuale' name='working_time_percentage'>
                            <FormInput name='working_time_percentage'/>
                        </FormGroup>

                        <FormGroup required label='Ore settimanali' name='working_time_other'>
                            <FormInput name='working_time_other'/>
                        </FormGroup>
                    </>
                }

                <FormGroup required label='Data assunzione' name='hire_date'
                           className="col-start-1">
                    <DatePicker
                        amPm={false}
                        clearable={true}
                        labelFormat={{month: "MM", year: "YYYY"}}
                        inputFormat='DD/MM/YYYY'
                        value={values?.hire_date || null}
                        onBlur={() => setFieldTouched("hire_date")}
                        onChange={value => setFieldValue("hire_date", value)}
                        name='hire_date'
                    />

                </FormGroup>
                <FormGroup label='Scadenza contratto' name='contract_end_date'>
                    <DatePicker
                        amPm={false}
                        clearable={true}
                        labelFormat={{month: "MM", year: "YYYY"}}
                        inputFormat='DD/MM/YYYY'
                        value={values?.contract_end_date || null}
                        onBlur={() => setFieldTouched("contract_end_date")}
                        onChange={value => setFieldValue("contract_end_date", value)}
                        name='contract_end_date'
                    />
                </FormGroup>
                <FormGroup label='Data cessazione' name='termination_date'>
                    <DatePicker
                        amPm={false}
                        clearable={true}
                        labelFormat={{month: "MM", year: "YYYY"}}
                        inputFormat='DD/MM/YYYY'
                        value={values?.termination_date || null}
                        onBlur={() => setFieldTouched("termination_date")}
                        onChange={value => setFieldValue("termination_date", value)}
                        name='termination_date'
                    />
                </FormGroup>

                <FormGroup label='N. proroghe' name='extension_count'>
                    <FormInput name='extension_count'/>
                </FormGroup>
                <FormGroup label='N. rinnovi' name='renewal_count'>
                    <FormInput name='renewal_count'/>
                </FormGroup>
                <FormGroup required label='CCNL applicato' name='applied_ccnl'>
                    <FormInput name='applied_ccnl'/>
                </FormGroup>
                <FormGroup required label='Livello' name='level'>
                    <FormInput name='level'/>
                </FormGroup>
            </div>


            <FormDescription title='Note'/>

            <div className='grid xl:grid-cols-1 gap-4'>
                <FormGroup label='Note interne' name='notes'>
                    <FormTextarea name='notes'/>
                </FormGroup>
                <FormGroup label='Note pubbliche' name='public_notes'>
                    <FormTextarea name='public_notes'/>
                </FormGroup>
            </div>
        </>
    );
};

export default EmploymentForm;
