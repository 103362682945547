import {useRequest} from "ahooks";
import {Form, Formik} from "formik";
import React, {useContext} from "react";
import {useNavigate} from "react-router-dom";
import * as Yup from "yup";

import FormCheckbox from "../../../../components/RefactoredForms/FormCheckbox";
import FormDescription from "../../../../components/RefactoredForms/FormDescription";
import FormGroup from "../../../../components/RefactoredForms/FormGroup";
import FormInput from "../../../../components/RefactoredForms/FormInput";
import FormSelect from "../../../../components/RefactoredForms/FormSelect";
import PageContainer from "../../../../components/layout/ProtectedLayout/_components/PageContainer";
import {Card, Notification} from "../../../../components/ui";
import CancelButton from "../../../../components/ui/Buttons/_components/CancelButton";
import SaveButton from "../../../../components/ui/Buttons/_components/SaveButton";
import PageHeader from "../../../../components/ui/Page/PageHeader";
import toast from "../../../../components/ui/toast";
import usersService from "../../../../services/usersService";
import {UsersContext} from "./Users";
import listService from "../../../../services/listService";

const UserCreate = () => {
    const navigate = useNavigate();
    const {roles} = useContext(UsersContext);
    const {data: documentTypes} = useRequest(listService.publicDocumentTypes);
    const {data: employeeDocumentTypes} = useRequest(listService.publicEmployeeDocumentTypes);

    const initialValues = {
        name: "",
        email: "",
        role: "",
        can_view_all_document_types: false,
        document_types_permitted: [],

        can_view_all_employee_document_types: false,
        employee_document_types_permitted: [],
    };

    const validationSchema = Yup.object().shape({
        name: Yup.string().max(100).required(),
        email: Yup.string().email().required(),
        role: Yup.object().nullable().required(),
        can_view_all_document_types: Yup.boolean().required(),
        document_types_permitted: Yup.array().required(),
        can_view_all_employee_document_types: Yup.boolean().required(),
        employee_document_types_permitted: Yup.array().required(),
    });

    return (
        <PageContainer>
            <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={(values, {setSubmitting}) => {
                    usersService
                        .store({...values, role: values?.role?.value})
                        .then(res => {
                            toast.push(<Notification title='Utente invitato con successo'
                                                     type='success'/>);
                            navigate("../" + res.uuid);
                        })
                        .catch(err => toast.push(<Notification title={err.response.data.message}
                                                               type='danger'/>))
                        .finally(() => setSubmitting(false));
                }}
            >
                {({isValid, isSubmitting, resetForm, values, setValues}) => (
                    <Form>
                        <Card
                            header={
                                <PageHeader
                                    title='Creazione utente'
                                    description="Inserisce l'angrafica di base dell'utente specificando il ruolo."
                                    toolbar={
                                        <>
                                            <CancelButton disabled={isSubmitting}
                                                          handleClick={() => resetForm()}/>
                                            <SaveButton loading={isSubmitting}
                                                        disabled={!isValid || isSubmitting}/>
                                        </>
                                    }
                                />
                            }
                        >
                            <div className='flex flex-col gap-4'>
                                <div className='grid xl:grid-cols-3 gap-4'>
                                    <FormGroup name={"name"} required label='Nome e cognome'>
                                        <FormInput name={"name"}/>
                                    </FormGroup>
                                    <FormGroup name={"email"} required label='Email'>
                                        <FormInput name={"email"} type='email'/>
                                    </FormGroup>
                                    <FormGroup name={"role"} required label='Ruolo'>
                                        <FormSelect
                                            name={"role"}
                                            options={roles}
                                            placeholder={"Seleziona un ruolo"}
                                            onChange={role =>
                                                setValues({
                                                    ...values,
                                                    role: role,
                                                    can_view_all_document_types: role?.value === 1 ? true : values.can_view_all_document_types,
                                                    can_view_all_employee_document_types: role?.value === 1 ? true : values.can_view_all_employee_document_types,
                                                    document_types_permitted: [],
                                                    employee_document_types_permitted: [],
                                                })
                                            }
                                        />
                                    </FormGroup>
                                </div>


                                <FormDescription
                                    title={"Tipologie documento aziendali"}
                                    desc={"Seleziona le tipologie di documento dell'azienda che l'utente ha il permesso di consulare."}
                                />
                                <FormGroup
                                    name={"can_view_all_document_types"}
                                    label={""}
                                    info={
                                        values.role?.value === 1
                                            ? "Il ruolo di amministratore ha di default il permesso di vedere tutte le tipologie di documento."
                                            : ""
                                    }
                                >
                                    <FormCheckbox
                                        name={"can_view_all_document_types"}
                                        disabled={values.role?.value === 1}
                                        label={"Permesso di vedere tutte le tipologie di documento"}
                                    />
                                </FormGroup>

                                {!values.can_view_all_document_types && values.role?.value !== "1" && (
                                    <div className='flex flex-col gap-4 mt-3'>

                                        <div className='grid xl:grid-cols-3 lg:grid-cols-2 gap-4'>
                                            {(documentTypes ?? []).map(dt => (
                                                <FormCheckbox
                                                    key={dt.value}
                                                    name={"document_types_permitted"}
                                                    label={dt.label}
                                                    disabled={values.role?.value === 1}
                                                    description={dt.description}
                                                    value={dt.value.toString()}
                                                />
                                            ))}
                                        </div>
                                    </div>
                                )}

                                <hr/>



                                <FormDescription
                                    title={"Tipologie documento dipendenti"}
                                    desc={"Seleziona le tipologie documento dei dipendenti al quale l'utente ha accesso."}
                                />

                                <FormGroup
                                    name={"can_view_all_employee_document_types"}
                                    label={""}
                                    info={
                                        values.role?.value === 1
                                            ? "Il ruolo di amministratore ha di default il permesso di vedere tutte le tipologie di documento."
                                            : ""
                                    }
                                >
                                    <FormCheckbox
                                        name={"can_view_all_employee_document_types"}
                                        disabled={values.role?.value === 1}
                                        label={"Permesso di vedere tutte le tipologie di documento dipendente"}
                                    />
                                </FormGroup>

                                {!values.can_view_all_employee_document_types && values.role?.value !== "1" && (
                                    <div className='flex flex-col gap-4 mt-3'>
                                        <div className='grid xl:grid-cols-3 lg:grid-cols-2 gap-4'>
                                            {(employeeDocumentTypes ?? []).map(dt => (
                                                <FormCheckbox
                                                    key={dt.value}
                                                    name={"employee_document_types_permitted"}
                                                    label={dt.label}
                                                    disabled={values.role?.value === 1}
                                                    description={dt.description}
                                                    value={dt.value.toString()}
                                                />
                                            ))}
                                        </div>
                                    </div>
                                )}
                            </div>
                        </Card>
                    </Form>
                )}
            </Formik>
        </PageContainer>
    );
};

export default UserCreate;
