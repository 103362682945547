import {HiPlus} from "@react-icons/all-files/hi/HiPlus";
import {isBefore} from "date-fns";
import React, {useEffect, useState} from "react";

import documentService from "../../../../services/documentService";
import CancelButton from "../../Buttons/_components/CancelButton";
import SaveButton from "../../Buttons/_components/SaveButton";
import Upload from "../../Upload";
import {DatePicker, Notification, toast} from "../../index";

const DocumentUploader = ({documentType, onDocumentAdded, companyId, month, year}) => {
    const [uploadDocument, setUploadDocument] = useState({
        file: null,
        filename: "",
        expiration: null,
        error: "",
    });

    const handleBeforeUpload = file => {
        if (file.length >= 1) {
            setUploadDocument({
                ...uploadDocument,
                file: file[0],
                filename: file[0]?.name,
            });
        }
    };

    useEffect(() => {
        if (uploadDocument.file !== null && !documentType.is_expiration_required) {
            handleUpload();
        }
    }, [uploadDocument.file]);

    const handleUpload = () => {
        documentService
            .store({
                file: uploadDocument.file,
                company_id: companyId,
                document_type_id: documentType.id,
                year: year,
                month: month,
                expiration: uploadDocument.expiration,
            })
            .then(res => {
                toast.push(<Notification title='Documento caricato con successo' type='success'/>);
                onDocumentAdded(res);
                handleRemove();
            })
            .catch(error => {
                handleRemove();
                toast.push(<Notification title={error.response.data.message} type='danger'/>)
            });
    };

    const handleRemove = () => {
        setUploadDocument({
            ...uploadDocument,
            file: null,
            filename: null,
            error: "",
        });
    };

    const checkDate = value =>
        setUploadDocument({
            ...uploadDocument,
            expiration: value,
            error: value
                ? isBefore(value, new Date())
                    ? "La data di scadenza deve essere almeno il giorno successivo"
                    : ""
                : "La data di scadenza è obbligatoria",
        });

    return (
        <div className='py-4'>
            {uploadDocument.file !== null && documentType.is_expiration_required ? (
                <>
                    <div className='py-4'>
                        <h6>Attenzione</h6>
                        La tipologia di file <span
                        className='font-bold'>{documentType?.name}</span>&nbsp; richiede
                        l'inserimento obbligatorio della
                        data di scadenza.
                        <br/>
                        Cortesemente inserire quanto richiesto per il file&nbsp;
                        <span className='font-bold'>{uploadDocument.filename}</span>.
                    </div>

                    <div className='form-item'>
                        <DatePicker
                            required={true}
                            amPm={false}
                            clearable={true}
                            labelFormat={{month: "MM", year: "YYYY"}}
                            inputFormat='DD/MM/YYYY'
                            placeholder='Data di scadenza'
                            value={uploadDocument.expiration}
                            onChange={checkDate}
                            loading={false}
                        />

                        <div className='form-explain'>{uploadDocument?.error}</div>
                    </div>

                    <div className='flex items-center justify-end gap-2'>
                        <CancelButton disabled={false} handleClick={handleRemove}/>
                        <SaveButton disabled={!!uploadDocument.error} type='button' loading={false}
                                    handleClick={handleUpload}/>
                    </div>
                </>
            ) : (
                <Upload
                    icon={<HiPlus/>}
                    label={`Carica ${documentType.name}`}
                    beforeUpload={handleBeforeUpload}
                    onFileRemove={handleRemove}
                    multiple={false}
                />
            )}
        </div>
    );
};

export default DocumentUploader;
