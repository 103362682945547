import {useFormikContext} from "formik";
import React from "react";

import FormCheckbox from "../../../../../../../../components/RefactoredForms/FormCheckbox";
import FormDescription from "../../../../../../../../components/RefactoredForms/FormDescription";
import FormGroup from "../../../../../../../../components/RefactoredForms/FormGroup";
import FormInput from "../../../../../../../../components/RefactoredForms/FormInput";
import FormSelect from "../../../../../../../../components/RefactoredForms/FormSelect";
import CancelButton from "../../../../../../../../components/ui/Buttons/_components/CancelButton";
import SaveButton from "../../../../../../../../components/ui/Buttons/_components/SaveButton";

const CompanyUserForm = ({documentTypes = [], employeeDocumentTypes = [], roles = []}) => {
    const {isValid, isSubmitting, resetForm, setValues, values} = useFormikContext();

    return (
        <div className='flex flex-col gap-4'>
            <div className='grid lg:grid-cols-3 gap-4'>
                <FormGroup name={"name"} required label='Nome e cognome'>
                    <FormInput name={"name"}/>
                </FormGroup>
                <FormGroup name={"email"} required label='Email'>
                    <FormInput name={"email"} type='email'/>
                </FormGroup>
                <FormGroup name={"role"} required label='Ruolo'>
                    <FormSelect
                        name={"role"}
                        options={roles}
                        placeholder={"Seleziona un ruolo"}
                        onChange={role =>
                            setValues({
                                ...values,
                                role: role,
                                can_view_all_document_types: role?.value === 1 ? true : values.can_view_all_document_types,
                                can_view_all_employee_document_types: role?.value === 1 ? true : values.can_view_all_employee_document_types,
                                document_types_permitted: [],
                                employee_document_types_permitted: [],
                            })
                        }
                    />
                </FormGroup>
            </div>

            <FormDescription
                title={"Tipologie documento aziendali"}
                desc={"Seleziona le tipologie di documento che l'utente ha il permesso di consulare."}
            />
            <FormGroup
                name={"can_view_all_document_types"}
                label={""}
                info={
                    values.role?.value === 1 ? "Il ruolo di amministratore ha di default il permesso di vedere tutte le tipologie di documento." : ""
                }
            >
                <FormCheckbox
                    name={"can_view_all_document_types"}
                    disabled={values.role?.value === 1}
                    label={"Permesso di vedere tutte le tipologie di documento"}
                />
            </FormGroup>
            <hr/>
            {values.can_view_all_document_types ||
                (values.role?.value !== 1 && (
                    <div className='flex flex-col gap-4'>

                        <div className='grid xl:grid-cols-3 lg:grid-cols-2 gap-4'>
                            {documentTypes.map(dt => (
                                <FormCheckbox
                                    key={dt.value}
                                    name={"document_types_permitted"}
                                    label={dt.label}
                                    disabled={values.role?.value === 1}
                                    description={dt.description}
                                    value={dt.value.toString()}
                                />
                            ))}
                        </div>
                    </div>
                ))}

            <FormDescription
                className='mt-6'
                title={"Tipologie documento dipendenti"}
                desc={"Seleziona le tipologie di documento dei dipendenti che l'utente ha il permesso di consulare."}
            />
            <FormGroup
                name={"can_view_all_employee_document_types"}
                label={""}
                info={
                    values.role?.value === 1 ? "Il ruolo di amministratore ha di default il permesso di vedere tutte le tipologie di documento." : ""
                }
            >
                <FormCheckbox
                    name={"can_view_all_employee_document_types"}
                    disabled={values.role?.value === 1}
                    label={"Permesso di vedere tutte le tipologie di documento dipendente"}
                />
            </FormGroup>
            <hr/>
            {values.can_view_all_employee_document_types ||
                (values.role?.value !== 1 && (
                    <div className='flex flex-col gap-4'>

                        <div className='grid xl:grid-cols-3 lg:grid-cols-2 gap-4'>
                            {employeeDocumentTypes.map(dt => (
                                <FormCheckbox
                                    key={dt.value}
                                    name={"employee_document_types_permitted"}
                                    label={dt.label}
                                    disabled={values.role?.value === 1}
                                    description={dt.description}
                                    value={dt.value.toString()}
                                />
                            ))}
                        </div>
                    </div>
                ))}

            <div className='gap-4 flex justify-end'>
                <CancelButton disabled={isSubmitting} handleClick={() => resetForm()}/>
                <SaveButton loading={isSubmitting} disabled={!isValid || isSubmitting}/>
            </div>
        </div>
    );
};

export default CompanyUserForm;
